import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { Getter, namespace } from 'vuex-class';

const name = 'info-settings-view';
const logger = new Logger(name);
const userModule = namespace('user');
const authModule = namespace('auth');

@Component({ name: name })
export default class InfoSettingsView extends Vue {
  @Getter('frontend_info')
  private version: any;
  @userModule.Action('updateUser')
  private updateUser!: any;
  @authModule.Getter('getAccount')
  private account!: any;
  @authModule.Action('signinSilent')
  private signinSilent!: any;

  language: string = '';
  @Watch('language')
  onLanguage(newv: string, oldv: string) {
    // check for exclude update when value changed from mount method
    if (oldv != '') {
      this.setLocale(newv);
    }
  }
  private async setLocale(local: string) {
    await this.updateUser({ id: this.account.profile.sub, language: local }).then(async () => {
      this.$root.$i18n.locale = local;
      this.$vuetify.lang.current = local;
      await this.signinSilent();
    });
  }
  @Watch('$vuetify.theme.dark')
  onTheme(newv: boolean, oldv: boolean) {
    if (newv != oldv) {
      this.switchTheme(newv);
    }
  }
  async switchTheme(val: boolean) {
    if ((val && this.account.profile.theme == 'light') || (!val && this.account.profile.theme == 'dark'))
      await this.updateUser({ id: this.account.profile.sub, theme: val ? 'dark' : 'light' }).then(async () => {
        // this.$vuetify.theme.dark = val == 'dark';
        await this.signinSilent();
      });
  }
  @Getter('info')
  private info!: any;
  get apiInfo() {
    return this.info;
  }

  mounted() {
    this.language = this.account.profile.language;
    this.$vuetify.theme.dark = this.account.profile.theme == 'dark';
  }
}
